import { Badge, Flex, Table, Text } from '@chakra-ui/react';
import { Tooltip } from 'components/ui/tooltip';
import { transactionStatusVariantMap } from 'constants/transactions';
import { useNavigate } from 'react-router-dom';
import { TransactionInstance } from 'types/shared-types';
import { TransactionStatus } from 'types/transactions';
import { getHumanReadableString } from 'utils/enum-helpers';
import { formatNumber } from 'utils/utils';

type TransactionTableRowProps = TransactionInstance & {
  visibleColumns: string[];
};
export const TransactionTableRow = ({
  id,
  external_id,
  date,
  total_amount,
  total_tax_amount_calculated,
  description,
  state,
  source,
  exempt,
  marketplace,
  status,
  type,
  customer,
  visibleColumns,
  currency,
  conversion_rate,
  destination_currency,
}: TransactionTableRowProps) => {
  const dateObject = new Date(date);
  const formattedDate = `${dateObject.toLocaleString('default', {
    month: 'short',
  })} ${dateObject.getDate()}, ${dateObject.getFullYear()}`;
  const navigate = useNavigate();
  const statusVariant = transactionStatusVariantMap[status as TransactionStatus];
  const amountTextColor = type === 'FULL_CREDIT_NOTE' || type === 'PARTIAL_CREDIT_NOTE' ? '#DD6B20' : '#262B47';
  const handleRowClick = () => {
    navigate(`/transactions/${id}`);
  };

  return (
    <Table.Row
      onClick={handleRowClick}
      _hover={{
        bg: '#EFEFF3',
        cursor: 'pointer',
      }}
      width={'100%'}
    >
      {visibleColumns?.includes('ID') && (
        <Table.Cell maxW="6.25rem">
          <Tooltip content={external_id}>
            <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
              <Text lineClamp={1}>{external_id}</Text>
            </Flex>
          </Tooltip>
        </Table.Cell>
      )}
      {visibleColumns?.includes('CUSTOMER NAME') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{customer.name}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('DESCRIPTION') && (
        <Table.Cell maxW="17.5rem">
          <Tooltip content={description}>
            <Flex alignItems="center" flexWrap="nowrap">
              <Text lineClamp={1} maxWidth={'100%'}>
                {description}
              </Text>
            </Flex>
          </Tooltip>
        </Table.Cell>
      )}
      {visibleColumns?.includes('DATE') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{formattedDate}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('JURISDICTION') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{state}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('AMOUNT') && (
        <Table.Cell textAlign="end">
          <Text color={amountTextColor}>{formatNumber(total_amount)}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('CALCULATED TAX') && (
        <Table.Cell textAlign="end">
          <Text color={amountTextColor}>{formatNumber(total_tax_amount_calculated)}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('CURRENCY') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{currency}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('CURRENCY CONVERSION RATE') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{conversion_rate}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('CONVERTED CURRENCY') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{destination_currency}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('TRANSACTION SOURCE') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{getHumanReadableString(source)}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('EXEMPT') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{exempt === 'NOT_EXEMPT' ? 'False' : 'True'}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('MARKETPLACE') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{marketplace ? 'True' : 'False'}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('STATUS') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            {statusVariant && <Badge colorPalette={statusVariant.variant}>{statusVariant.title}</Badge>}
          </Flex>
        </Table.Cell>
      )}
      <Table.Cell></Table.Cell> {/* Header showing ColumnFilter so tr need to keep proper alignment */}
    </Table.Row>
  );
};
