import { Grid, GridItem } from '@chakra-ui/react';
import MiniStatistics from 'pages/Dashboard/components/mini-statistics';
import { formatCurrency, formatNumber } from 'utils/utils';

type TransactionMetricProps = {
  transactionMetrics: {
    total_transactions_count: number;
    total_sales: number;
    total_taxable_sales: number;
    total_incomplete_address: number;
  };
};

const TransactionVanityMetric = ({ transactionMetrics }: TransactionMetricProps) => {
  const { total_transactions_count, total_sales, total_taxable_sales, total_incomplete_address } = transactionMetrics;
  const metrics = [
    {
      title: 'Total Transactions',
      amount: formatNumber(total_transactions_count, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    },
    { title: 'Total Sales', amount: formatCurrency(total_sales) },
    { title: 'Taxable Sales', amount: formatCurrency(total_taxable_sales) },
    {
      title: 'Incomplete Address',
      amount: formatNumber(total_incomplete_address, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    },
  ];

  return (
    <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }} width="100%" mb={4}>
      {metrics.map(metric => (
        <GridItem key={metric.title}>
          <MiniStatistics
            title={metric.title}
            amount={metric.amount}
            cardStyle={{
              height: '100%',
              borderRadius: '0px',
            }}
          />
        </GridItem>
      ))}
    </Grid>
  );
};

export default TransactionVanityMetric;
