import { HStack, Skeleton, Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getInitialImportStatus, getTransactionMetrics, TRANSACTION_METRICS_KEY } from 'apis/transaction-api';
import { getTransactions, TRANSACTION_STATE_KEY } from 'apis/transactions';
import AppHeader from 'app/app-header';
import { TransactionTrackingToolbar } from 'app-header-toolbar/transaction-header-toolbar';
import AlertBanner from 'components/alert/alert';
import { CountrySelector } from 'components/country-selector/country-selector';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { useOrg } from 'hooks/useOrg';
import { DEFAULT_PAGE_SIZE, useTableFilters } from 'hooks/useTableFilters';
import { CountryCodeEnum } from 'types/shared-types';

import { TransactionTable } from './components/table';
import TransactionVanityMetric from './components/transaction-vanity-metric';

const Transaction = () => {
  const transactionTableFilters = useTableFilters({
    order_by: ['-date', 'state'],
  });
  const {
    page,
    size,
    order_by,
    address_status__in,
    status,
    state_code,
    country,
    filing_id,
    transaction_type,
    transaction_source,
    date__gte,
    date__lte,
    search_query,
    marketplace,
    setFilters,
  } = transactionTableFilters;
  const { orgId } = useOrg();

  const { isPending, data } = useQuery({
    queryKey: [
      TRANSACTION_STATE_KEY,
      orgId,
      page,
      size,
      order_by,
      address_status__in,
      status,
      state_code,
      country,
      filing_id,
      transaction_type,
      transaction_source,
      date__gte,
      date__lte,
      search_query,
      marketplace,
    ],
    queryFn: async () => {
      const { data } = await getTransactions(orgId, {
        page,
        size,
        address_status__in,
        status,
        state_code,
        country,
        filing_id,
        transaction_type,
        transaction_source,
        date__gte,
        date__lte,
        search_query,
        order_by,
        marketplace,
      });
      return data;
    },
    refetchOnMount: 'always',
    staleTime: 0,
    refetchOnWindowFocus: true,
  });

  const { isPending: isInitialStatusPending, data: statusData } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, 'TRANSACTION_STATUS', orgId],
    queryFn: async () => {
      const { data } = await getInitialImportStatus(orgId);
      return data;
    },
  });

  const { isPending: isTransactionMetricsPending, data: transactionMetrics } = useQuery({
    queryKey: [TRANSACTION_METRICS_KEY, orgId],
    queryFn: async () => {
      const { data } = await getTransactionMetrics(orgId);
      return data;
    },
  });

  const handleCountryChange = (country: CountryCodeEnum) => {
    setFilters({
      country,
      state_code: undefined,
    });
  };

  const transactionData = data?.items || [];
  const totalPages = data?.pages ?? 0;
  const isPaginationEnable = transactionData.length > 0 && (data?.total ?? 0) > DEFAULT_PAGE_SIZE;

  return (
    <Stack>
      <HStack gap={1} justifyContent={'space-between'} w={'full'}>
        <HStack>
          <AppHeader />
          <CountrySelector country={country as CountryCodeEnum} onCountryChange={handleCountryChange} />
        </HStack>
        <TransactionTrackingToolbar tableFilters={transactionTableFilters} />
      </HStack>
      {!isTransactionMetricsPending && transactionMetrics ? (
        <TransactionVanityMetric transactionMetrics={transactionMetrics} />
      ) : (
        <Skeleton height="106px" mb={4} />
      )}
      {!isInitialStatusPending && !statusData?.initial_import_complete_status && (
        <AlertBanner
          message="Transaction import is still in progress, so the numbers may not be the latest. We’ll email you once the
            import is complete."
          width={{ xl: '75%' }}
        />
      )}

      <TransactionTable
        data={transactionData}
        isFilterApplied={Object.values({
          address_status__in,
          status,
          state_code,
          country,
          filing_id,
          transaction_type,
          transaction_source,
          date__gte,
          date__lte,
          marketplace,
        }).some(Boolean)}
        isSearchApplied={!!search_query}
        tableFilters={transactionTableFilters}
        isPending={isPending}
      />

      {!isPending && isPaginationEnable && (
        <PaginationButtons size={size} currentPage={page} totalPages={totalPages} setFilters={setFilters} />
      )}
    </Stack>
  );
};

export default Transaction;
