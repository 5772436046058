import { TableFilterPopover } from 'components/filter';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';

import { TransactionFilter } from './transaction-filter';

type TransactionPopoverProps = {
  tableFilters: UseTableFiltersType;
};
export const TransactionPopover = ({ tableFilters }: TransactionPopoverProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const {
    address_status__in,
    status,
    state_code,
    country,
    filing_id,
    transaction_type,
    transaction_source,
    date__gte,
    date__lte,
    marketplace,
  } = tableFilters;

  const hasFilter = Object.values({
    address_status__in,
    status,
    state_code,
    country,
    filing_id,
    transaction_type,
    transaction_source,
    date__gte,
    date__lte,
    marketplace,
  }).some(Boolean);
  return (
    <TableFilterPopover hasFilter={hasFilter} open={open} onOpenChange={({ open }) => setOpen(open)}>
      <TransactionFilter
        tableFilters={tableFilters}
        onClose={() => {
          setOpen(false);
        }}
      />
    </TableFilterPopover>
  );
};
