import { HStack, Icon, IconButton } from '@chakra-ui/react';
import { KSheetIcon } from 'components/icons';
import { Tooltip } from 'components/ui/tooltip';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import ExportTransactions from 'pages/Transactions/components/export-transaction';
import { TransactionPopover } from 'pages/Transactions/components/transaction-filter/transaction-popover';
import TransactionSearch from 'pages/Transactions/components/TransactionSearch';

const KINTSUGI_SHEET_URL = 'https://workspace.google.com/marketplace/app/kintsugi_sheets/160068196398';

type TransactionTrackingToolbarProps = {
  tableFilters: UseTableFiltersType;
};

export const TransactionTrackingToolbar = ({ tableFilters }: TransactionTrackingToolbarProps) => {
  return (
    <HStack>
      <TransactionSearch tableFilters={tableFilters} />
      <Tooltip positioning={{ placement: 'bottom-end' }} content={'Kintsugi Sheets'}>
        <IconButton
          aria-label={'Kintsugi Sheets'}
          mr={1}
          variant={'transparent-with-icon'}
          onClick={() => window.open(KINTSUGI_SHEET_URL, '_blank')}
        >
          <Icon size="xl">
            <KSheetIcon />
          </Icon>
        </IconButton>
      </Tooltip>
      <ExportTransactions />
      <TransactionPopover tableFilters={tableFilters} />
    </HStack>
  );
};
