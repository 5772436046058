import {
  CheckboxCheckedChangeDetails,
  DialogBody,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { exportTransactionsReport } from 'apis/transaction-api';
import { ExcelIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import {
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { Radio, RadioGroup } from 'components/ui/radio';
import { Tooltip } from 'components/ui/tooltip';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import useAsyncActionLoader from 'hooks/useAsyncActionLoader';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

interface FormValues {
  country_code: string;
  start_year: number;
  end_year: number;
  include_invalid_addresses?: boolean;
}

const currentYear = new Date().getFullYear();
const last8Years = Array.from({ length: 8 }, (_, i) => currentYear - i);

const validationSchema = Yup.object().shape({
  country_code: Yup.string().required('Country is required'),
  start_year: Yup.number()
    .min(currentYear - 8, `Start Year must be greater than ${currentYear - 8}`)
    .max(currentYear, `Start Year must be less than ${currentYear}`)
    .required('Start Year is required'),
  end_year: Yup.number()
    .min(currentYear - 8, `End Year must be greater than ${currentYear - 8}`)
    .max(currentYear, `End Year must be less than ${currentYear}`)
    .required('End Year is required'),
});

const ExportTransactions = () => {
  const { orgId } = useOrg();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const { loading, executeAction } = useAsyncActionLoader(exportTransactionsReport);
  const [reportType, setReportType] = useState('transactionSummary');
  const { open, onOpen, onClose } = useDisclosure();
  const options = last8Years.map(year => ({
    value: String(year),
    label: String(year),
  }));
  const formik = useFormik<FormValues>({
    initialValues: {
      country_code: 'US',
      start_year: currentYear - 1,
      end_year: currentYear,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      try {
        await executeAction(orgId, reportType, values);
        onClose();
        handleSuccessNotification('Transaction Report Export Started. Check your email for a download link.');
      } catch (e) {
        handleFailNotification(e);
        onClose();
      }
    },
  });

  const handleInvalidAddresses = ({ checked: isChecked }: CheckboxCheckedChangeDetails) => {
    if (isChecked) {
      formik.setFieldValue('include_invalid_addresses', true);
    } else {
      const newValues = { ...formik.values };
      delete newValues.include_invalid_addresses;
      formik.setValues(newValues);
    }
  };

  useEffect(
    () => () => {
      formik.resetForm();
    },
    []
  );

  return (
    <DialogRoot
      closeOnInteractOutside={false}
      open={open}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogTrigger asChild>
        <Tooltip positioning={{ placement: 'bottom-end' }} content={'Export Excel'}>
          <IconButton aria-label={'Export Transaction'} mr={1} variant={'transparent-with-icon'} onClick={onOpen}>
            {loading ? (
              <Spinner />
            ) : (
              <Icon size="xl">
                <ExcelIcon />
              </Icon>
            )}
          </IconButton>
        </Tooltip>
      </DialogTrigger>
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger />
        <DialogHeader>
          <DialogTitle>Send Report</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Flex direction="column" gap={3}>
            <Text>
              The report will be sent to your email address and will arrive in the next 1-2 minutes depending on how
              much data is in your organization.
            </Text>
            <Text>Select report option:</Text>
            <RadioGroup
              defaultValue="transactionSummary"
              value={reportType}
              onValueChange={({ value }) => setReportType(value)}
            >
              <Stack>
                <Radio value="transactionDetails">
                  <Text>Transaction Details</Text>
                </Radio>
                <Radio value="transactionSummary">
                  <Text>Transaction Summary</Text>
                </Radio>
              </Stack>
            </RadioGroup>
            <Flex gap={2}>
              <Field label="Country">
                <NativeSelectRoot>
                  <NativeSelectField value={formik.values.country_code} onChange={formik.handleChange}>
                    <option value="US">US</option>
                  </NativeSelectField>
                </NativeSelectRoot>
              </Field>
              <Field
                label="Start Year"
                invalid={!!(formik.errors.start_year && formik.touched.start_year)}
                errorText={formik.errors.start_year}
                required
              >
                <NativeSelectRoot>
                  <NativeSelectField
                    id="start_year"
                    name="start_year"
                    value={formik.values.start_year}
                    onChange={formik.handleChange}
                  >
                    {options.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </NativeSelectField>
                </NativeSelectRoot>
              </Field>
              <Field
                label="End Year"
                invalid={!!(formik.errors.end_year && formik.touched.end_year)}
                errorText={formik.errors.end_year}
                required
              >
                <NativeSelectRoot>
                  <NativeSelectField
                    id="end_year"
                    name="end_year"
                    value={formik.values.end_year}
                    onChange={formik.handleChange}
                  >
                    {options.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </NativeSelectField>
                </NativeSelectRoot>
                {formik.errors.end_year && formik.touched.end_year && (
                  <Text color={'#E53E3E'}>{formik.errors.end_year}</Text>
                )}
              </Field>
            </Flex>
            <Checkbox
              disabled={reportType == 'transactionSummary'}
              checked={!!formik.values.include_invalid_addresses}
              onCheckedChange={handleInvalidAddresses}
            >
              {' '}
              Include transactions with invalid addresses{' '}
            </Checkbox>
          </Flex>
        </DialogBody>
        <DialogFooter>
          <Flex gap={4}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={loading}
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={() => formik.handleSubmit()}
            >
              Submit
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default ExportTransactions;
